import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import {
  Hero,
  Gallery,
  Movie,
  Content,
  Banner,
  Section,
  Heading,
  TimeItem,
} from "../../page_components/krakow-sky-run"

const KrakowSkyRun = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/krakow-sky-run/">
      <SEO title="Krakow Sky Run" />

      <Hero description="Wyprzedź konkurencję i bądź liderem w BIEGU NA SZYBKOŚĆ z UNITY CENTRE, partner wydarzenia ABSL Summit 2024." />
      <Content
        roundTitle={
          <>
            BIEG
            <br />
            odbędzie się w dwóch turach
          </>
        }
        roundDescription={{
          first:
            "Dla zarejestrowanych uczestników ABSL Summit oraz pracowników firm, których przedstawiciele zarejestrowali się na ABSL Summit.",
          second:
            "Dla pracowników organizacji sektora usług biznesowych z siedzibą w Krakowie. Zapraszamy wszystkie firmy!",
        }}
        lang={lang}
      >
        <h2>
          Kraków Sky Run –<br />
          Powered by UNITY Centre
        </h2>
        <p>
          Wyprzedź konkurencję i bierz udział w wyjątkowym biegu na UNITY Tower
          przy ulicy Aleksandra Lubomirskiego 20 w Krakowie. Biegnij na 25
          piętro, daj z siebie wszystko, aby uzyskać najlepszy czas i zdobyć
          nagrody!
        </p>
      </Content>
      <Banner>
        <h4>
          Mamy już za sobą dwie edycje
          <br />
          Kraków Sky Run 2024 - Powered by Unity Centre, <br />
          które były niesamowitymi wydarzeniami.
        </h4>
        <h3>Kolejna edycja odbędzie się w 2025 roku.</h3>
      </Banner>
      <Gallery />
      <Movie />
      <Section
        heading={
          <Heading
            title="Kraków Sky Run 2023"
            description="Ponad 90 osób wzięło udział w wydarzeniu, którego celem było pokonanie trasy biegu na 25. piętro wieżowca. Uczestników przywitały prezenty i nagrody, a także strefa relaksu zniesamowitym widokiem na panoramę miasta."
          />
        }
        content={[
          {
            headings: null,
            elements: [
              <img
                src={require("../../assets/images/krakow-sky-run/time-icon.svg")}
                alt=""
              />,
            ],
            isHiddenMobile: true,
          },
          {
            headings: ["Rekordy czasowe"],
            elements: [
              <TimeItem time="3:14" title="kobiety" />,
              <TimeItem time="2:26" title="mężczyźni" />,
            ],
          },
        ]}
        isContentBottomAligned
        isGray
      />
      <Section
        heading={
          <Heading
            title="Kraków Sky Run 2024"
            description="Do drugiej edycji zapisało się 120 osób. Wydarzenie spotkało się z dużym zainteresowaniem. Każdy został nagrodzony upominkiem oraz pamiątkowym medalem. Zapraszamy do obejrzenia relacji z wydarzenia."
          />
        }
        content={[
          {
            headings: ["Rekordy czasowe", "Kobiety"],
            elements: [
              <TimeItem
                time="3:17"
                title="I miejsce"
                labels={["MONIKA PRZYBYŁO"]}
              />,
              <TimeItem
                time="3:18"
                title="II miejsce"
                labels={["MARGARYTA IEVTUKH"]}
              />,
              <TimeItem
                time="3:29"
                title="III miejsce"
                labels={["JULIANNA SIWEK", "ANETA SAMKOFF"]}
              />,
            ],
          },
          {
            headings: ["Rekordy czasowe", "Mężczyźni"],
            elements: [
              <TimeItem
                time="2:29"
                title="I miejsce"
                labels={["PAWEŁ BARCIK"]}
              />,
              <TimeItem
                time="2:31"
                title="II miejsce"
                labels={["ANDRZEJ OCHOWICZ"]}
              />,
              <TimeItem
                time="2:52"
                title="III miejsce"
                labels={["TYMOTEUSZ KRUK"]}
              />,
            ],
          },
        ]}
      />
      <Banner
        image={require("../../assets/images/krakow-sky-run/medals-image.jpg")}
      />
      <Banner
        image={require("../../assets/images/krakow-sky-run/group-image.jpg")}
      >
        <h4>
          Serdecznie zapraszamy
          <br />
          na trzecią edycję Kraków Sky Run 2025 - Powered by Unity Centre!
        </h4>
        <p>
          Dołącz do nas na to niesamowite wydarzenie i przeżyj niezapomniane
          chwile.
          <br />
          Więcej informacji już wkrótce.
        </p>
        <h3>Do zobaczenia na starcie!</h3>
      </Banner>
    </Layout>
  )
}

export default KrakowSkyRun
